#container{
    font-size: 2rem; 
    display: flex;   
    min-height: fit-content;
    background: linear-gradient(180deg, rgba(255,255,255,1) 80%, rgba(0,153,255,0.7) 100%);;
    /* background-color: rgb(201, 130, 130); */
}

#sara{    
    max-height:400px;   
    border-radius: 25px;
    filter:grayscale(80%) drop-shadow(22px 16px 16px #0099ff); 
    transition: filter 0.7s;   
}
#sara:hover{   
    filter:grayscale(10%) drop-shadow(22px 16px 16px #006eff);
}

#info{
    min-width: fit-content;
}

#info ul ::marker {
    content: none; 
}

#info{
    color: #000000;
    text-decoration: none;
}

#info li img{
    max-height: 40px;
    filter: saturate(40%);
}

#info li:hover h3{
 color: white;
 background-color: #0099ff;
 border-radius: 10px;
 cursor: pointer;
}

#info li:hover img{
    cursor: pointer;
    transform:scale(1.2);
    filter: saturate(100%);
}

.myBorder{
    border: 2px solid #0099ff;
}

.over:hover{
    color: white;
    background-color: #0099ff;
    border-radius: 10px;
    cursor: pointer; 
}

.textFormat{
    color:#000000;
    text-align: justify;
}

.textFormat h4{
    font-weight: 600;
}
