.leaflet-container {
    max-width: 100%;
    max-height: 350px; 
    border-radius: 20px;    
  }

.generalMapContainer{
  position: relative;
}

.test{
  max-width: 100%;
  padding: 0.5rem;
  background-color: rgb(255, 255, 255);
  position: absolute;
  bottom:0;
  right: 0.7rem;
  left: 0.7rem;
  border-radius: 0 0 20px 20px;
  z-index: 2;
}