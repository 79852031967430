.studioGalleryBG{
    background-color: #0099ffb3;  
    text-align: center;
}

.studioGalleryBG h4{
    font-weight: 600;
}

.sliderImg{
    filter:drop-shadow(6px 5px 10px #ffffff);
    padding: 3%;    
    margin: 20px 0;
    border-radius: 10%;
}

@media only screen and (min-width: 1700px) {
    .slider{
        width: 45%;    
    }
}
@media only screen and (min-width: 1400px) and (max-width: 1699px) {
    .slider{
        width: 60%;    
    }
}
@media only screen and (min-width: 991px) and (max-width: 1399px) {
    .slider{
        width: 70%;    
    }
}
@media only screen and (min-width: 700px) and (max-width: 990px) {
    .slider{
        width: 80%;    
    }
}
@media only screen and (min-width: 200px) and (max-width: 699px) {
    .slider{
        width: 90%;    
    }
}
