@media only screen and (min-width: 1200px) {
  .myNavBar {
    background-color: transparent;
    position: relative;
    height: 300px;
    display: flex;
    align-items: flex-start;
  }
  #navBrand {
    color: #0099ff;
    font-size: 3.0rem;
    font-weight: 700;    
    margin: 2rem 1rem;
    background-color: #ffffffd2;
    padding: 0px 10px;
    border-radius: 25px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
  .myNavBar {
    background-color: transparent;
    position: relative;
    height: 150px;
    display: flex;
    align-items: flex-start;
  }
  #navBrand {
    color: #0099ff;
    font-size: 2.5rem;
    font-weight: 600;    
    margin: 1rem;
    background-color: #ffffffd2;
    padding: 0px 15px;
    border-radius: 25px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 999px) {
  .myNavBar {
    background-color: transparent;
    position: relative;
    height: 150px;
    display: flex;
    align-items: flex-start;
  }
  #navBrand {
    color: #0099ff;
    font-size: 2rem;
    font-weight: 500;    
    margin: 0.5rem 0.2rem;
    background-color: #ffffffd2;
    padding: 0px 5px;
    border-radius: 25px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 699px) {
    .myNavBar {
      background-color: transparent;
      position: relative;
      height: 80px;
      display: flex;
      align-items: flex-start;
      padding: 0;
    }
    #navBrand {
      color: #0099ff;
      font-size: 1.3rem;
      font-weight: 400;
      margin: 0rem;
      background-color: #ffffffd2;
      padding: 0px 5px;
      border-radius: 15px;
      height: fit-content;
    }
    #navBrand2 {
      color: #0099ff;
      font-size: 1.3rem;
      font-weight: 400;
      margin: 0rem;      
      background-color: #ffffffd2;
      padding: 0px 5px;
      border-radius: 15px;
    }
  }



.navMenuText {
  color: #0099ff;
  font-size: 1.4rem;
  background-color: #ffffffaf;
  border-radius: 20px;
}

#basic-nav-dropdown {
  color: #0099ff;
  font-size: 1.4rem;
}

.navMenuText:hover {
  color: blue;
}

.navMenu {
  display: flex;
  justify-content: flex-end;
}
