.myModal{
    background-color: #0099ff4b;
}

.modalHeader{
    background-color: #0099ff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: inherit;
    color: white;  
}

#modalTitle h2{
    margin: 0;      
}

.modalBody{
    text-align: justify; 
}

.modalButton{
    background-color: #0099ff;
    border: 1px solid #0099ff;
    font-size: 1.1rem;
}