@media only screen and (min-width: 1200px) {
  #navCarousel {
    height: fit-content;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 0;
    object-fit: cover;
    object-position: center;
    opacity: 1;
    z-index: -1;
    overflow: hidden;
    width: 100%;
  }

  .carItem {
    display: block;
    /* height: 100%; */
    width: 100%;
    overflow: hidden;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
    #navCarousel {
      height: fit-content;
      background-color: rgb(255, 255, 255);
      position: absolute;
      top: 0;
      object-fit: cover;
      object-position: center;
      opacity: 1;
      z-index: -1;
      /* width: 100%; */
      overflow: hidden;
    }
  
    .carItem {
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  @media only screen and (min-width: 200px) and (max-width: 899px) {
    #navCarousel {
      height: fit-content;
      background-color: rgb(255, 255, 255);
      position: absolute;
      top: 0;
      object-fit: cover;
      object-position: center;
      opacity: 1;
      z-index: -1;
      width: 100%;
      overflow: hidden;
    }
  
    .carItem {
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }