@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap');

body{ 
  font-family: 'Dosis', sans-serif;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
  z-index: 1;
}